"use client";

import React from "react";
import Image from "next/image";
import { Box, Container, Grid, Typography, alpha } from "@mui/material";
import EnquiryButton from "@/src/app/(client)/_features/components/EnquiryButton";

export default function SecondaryBanner() {
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(to right, #CEE5FD, #FFF)" // Gradient to right for light mode
            : `linear-gradient(to right, #02294F, ${alpha("#090E10", 0.0)})`, // Gradient to right for dark mode
        backgroundSize: "200% 100%", // Set background size to 200% for smooth transition
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 4, sm: 12 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Grid container justifyContent={"space-between"} alignItems={"start"}>
          <Grid item xs={12} sm={7}>
            <Typography variant="h2">Have Questions? 🤔 Let’s Talk!</Typography>
            <Typography variant="h6">
              Our team is here to guide you every step of the way.
            </Typography>
            <Typography sx={{ mb: 2 }} variant="subtitle1">
              📞 Call us now, and start your journey to success!
            </Typography>
            <EnquiryButton action="ThankYouMessage" text="Call Now" />
          </Grid>
          <Grid item xs={12} sm={4} textAlign={"center"}>
            <Image
              loading="lazy"
              width={270}
              height={200}
              alt=""
              src="/images/woman-thinking.webp"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
