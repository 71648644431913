"use client";
import React from "react";
import { Box, Container, Typography } from "@mui/material";

export default function AboutUsHome() {
  return (
    <Box bgcolor="#000" color="#fff">
      <Container
        maxWidth="lg"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 3 },
        }}
      >
        <Typography variant="h1" textAlign={"center"} color="primary">
          About Us
        </Typography>
        <Typography variant="body1">
          At Brain Mentors, we’re more than trainers—we’re dedicated developers
          committed to your success. Our award-winning instructors bring years
          of corporate experience, recognized for excellence and integrity,
          ensuring that every lesson is rooted in real industry insight and high
          standards.
        </Typography>
        <Typography variant="body1">
          We bridge the gap between college and career, providing genuine,
          hands-on training to prepare you for top MNC roles and exceed industry
          expectations.
        </Typography>
        <Typography variant="body1">
          Our mission is simple yet powerful: to create high-quality developers
          for our nation. We focus on building true problem-solvers who
          understand industry demands, master best practices, and are ready to
          contribute from day one. With Brain Mentors, your growth is our
          promise.
        </Typography>
      </Container>
    </Box>
  );
}
