"use client";

import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SharedCarousel from "./Carousel";

export default function TestimonialsList() {
  const testimonials = [
    {
      _id: "661667ca4feb26e0339d0542",
      name: "Aastha Dawar",
      description:
        "I have been a proud student of Brain Mentors Pvt Ltd from past 3years. I started my journey here with most helpful and kind person Amit Sir. He is the one who made me a Software Developer and made me understand what actually a Engineer Is.",
      photo_url:
        "https://brain-mentors-public-assets.s3.ap-south-1.amazonaws.com/testimonials/aastha_dawar.jpg",
      company_or_collage: "SVNIT",
    },
    {
      _id: "6616683d4feb26e0339d0d8b",
      name: "Aman Garg",
      description:
        "Let me make this review helpful for those searching for coaching Institutes for doing training in java or whatever. I came to know about the institute through a friend of mine. After being a little skeptic about joining it when comparing it with bigger names like CMS and NIIT, I decided to take a demo classfor core java.",
      photo_url: null,
      company_or_collage: "DU ",
    },
    {
      _id: "661668ba4feb26e0339d19f8",
      name: "Rashmi Goyal",
      description:
        " I have been a part of the Brain Mentors family for the past year. Brain mentors provide you with a platform where you can enhance your skills. The projects that we do in college are very different from the projects that are made in the software industry.",
      photo_url:
        "https://brain-mentors-public-assets.s3.ap-south-1.amazonaws.com/testimonials/rashmi_goyal.png",
      company_or_collage: "MU",
    },
    {
      _id: "661668f64feb26e0339d1e1e",
      name: "Jatin Aanand",
      description:
        "Easily explain All type of complexity of Coding like Concept of any Programimg language and Steps of any Framework.I love Coding Complexity.When I entered first time in Brain Mentor,then i attend first demo class of Java Core(teached by Mr.Amit Shrivastava Sir).",
      photo_url: null,
      company_or_collage: "JNU ",
    },
    {
      _id: "661669264feb26e0339d1e21",
      name: "Anmol Gupta",
      description:
        "Amit Sir is an expert and a magnificent teacher . His way of teaching is most appreciable. Every thing is taught in deep perspective. The curriculum is up to date and I got sound knowledge of Java Script,React ,Node and MongoDB . He is a great teacher .My Overall experience is very nice and best ever inan online class.",
      photo_url: null,
      company_or_collage: "JNU ",
    },
    {
      _id: "6616769a4feb26e0339dda0e",
      name: "Aastha Dawar",
      description:
        "I am proud student of Brain Mentors Pvt Ltd from past 3years. I started my journey here with most helpful and kind person Amit Sir. He is the one who made me a Software Developer and made me understand what actually a Engineer Is.",
      photo_url: null,
      company_or_collage: "MU",
    },
    {
      _id: "661677324feb26e0339de3b9",
      name: "Rashmi Goyal",
      description:
        "I have been a part of brain mentors family from the past one year. Brain mentors actually provide you a platform where you can enhance your skills. The projects that we do in college are actually very different from the projects that are made in software industry.",
      photo_url: null,
      company_or_collage: "SVNIT ",
    },
    {
      _id: "661669c34feb26e0339d292d",
      name: "Utsav Gupta",
      description:
        "When I joined BMPL, I had no knowledge about coding. I started by learning C++ here. Currently I'm studying MEAN stack and UI Development here. All this within a span of 45 days. The faculty is amazing. They focus more on practical aspects rather than theory. Projects are very interesting although a bit time consuming.",
      photo_url: null,
      company_or_collage: "Indraprastha",
    },
  ];

  return (
    <Box>
      <Container
        id="testimonials"
        sx={{
          mt: 5,
          pt: { xs: 4, sm: 6 },
          pb: { xs: 4, sm: 12 },
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          textAlign={"center"}
          variant="h2"
          mb={5}
          color="text.primary"
        >
          🗣️ Student Voices: Why They Love Brain Mentors! ❤️
        </Typography>

        <SharedCarousel>
          {testimonials?.map((testimonial) => (
            <Card
              key={testimonial._id}
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                m: 1,
                height: "80%",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={2}
              >
                <Avatar
                  sx={{ height: 80, width: 80 }}
                  alt={testimonial.name}
                  src={testimonial.photo_url}
                  slotProps={{
                    img: {
                      loading: "lazy",
                    },
                  }}
                />
                <Typography fontWeight="bold" variant="subtitle2">
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {testimonial.company_or_collage}
                </Typography>
              </Box>
              <CardContent>
                <Typography variant="body1" color="text.secondary">
                  {testimonial.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </SharedCarousel>
      </Container>
    </Box>
  );
}
