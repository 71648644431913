"use client";

import React from "react";
import {
  Container,
  Box,
  Typography,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import useSWR from "swr";
import CourseBanner from "./CourseBanner";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OurCourses() {
  const [value, setValue] = React.useState(0);
  const { data, isLoading, error } = useSWR("/api/category", async () => {
    const response = await fetch("/api/category", {
      method: "GET",
    });

    return await response.json();
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <p>{error?.message}</p>;
  }

  if (data) {
    const categories = data.categories;
    if (!(categories.length > 0)) {
      return <Typography align="center">No categories found</Typography>;
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
      <Container
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
        }}
      >
        <Typography textAlign={"center"} variant="h2">
          Know More About Our Courses
        </Typography>
        <Typography textAlign={"center"} color="text.secondary" variant="body1">
          Deep Dive into Our Comprehensive Course Selection: Uncover the
          Details, Benefits, and Opportunities Awaited
        </Typography>

        <Box
          mt={4}
          sx={{
            width: { sm: "100%", md: "100%" },
            textAlign: { sm: "left", md: "center" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {categories
              .filter((category) => category.is_active)
              .map((category, index) => {
                return (
                  <Tab
                    sx={{ fontSize: "1.1rem" }}
                    key={index}
                    label={category.label}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
        </Box>

        {categories
          .filter((category) => category.is_active)
          .map((category, index) => {
            return (
              <CustomTabPanel key={index} value={value} index={index}>
                <CourseBanner courseCategory={category.title} />
              </CustomTabPanel>
            );
          })}
      </Container>
    );
  }
}
