"use client";
import React from "react";
import Image from "next/image";
import {
  CardActionArea,
  Chip,
  Card,
  Stack,
  CardContent,
  Typography,
} from "@mui/material";
import { baseURL } from "@/src/config";
import { useRouter } from "next/navigation";

export default function CourseCard({ course, requestFrom = "" }) {
  const [isClicking, setIsClicking] = React.useState(false);
  const router = useRouter();
  const goToCourse = async () => {
    try {
      setIsClicking(true);

      const response = await fetch(
        `${baseURL}/api/courses/page/${course._id}`,
        {
          method: "GET",
        }
      );

      const result = await response.json();
      setIsClicking(false);

      if (result && result?.slug) {
        if (requestFrom === "LandingPage") {
          router.push(`/landing/${result?.slug}`);
        } else {
          router.push(`/${result?.slug}`);
        }
      }
    } catch (err) {}
  };
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardActionArea
        disabled={isClicking}
        onClick={goToCourse}
        sx={{ height: "100%" }}
      >
        <Image
          alt={`${course.title}`}
          src={course.image}
          height={150}
          width={300}
          sizes="100vw"
          quality={60}
          loading="lazy"
          style={{ objectFit: "cover" }}
        />
        <CardContent sx={{ height: "100%" }}>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              fontSize: "1rem",
            }}
            gutterBottom
          >
            {course.title}
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flexWrap: "wrap" }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="bold"
            >
              {`₹${course.price}`}
            </Typography>
            <Chip sx={{ mt: 1 }} label={course.language} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
