"use client";

import React from "react";
import Image from "next/image";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { Box } from "@mui/material";

export default function CompanyImageCarousel({ logos, items = 4 }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlay
      autoPlaySpeed={2000}
      centerMode={false}
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      customTransition="transform 500ms ease-in-out"
      itemClass="carousel-item-padding"
      keyBoardControl
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={2}
    >
      {logos.map((logo, index) => (
        <Box
          sx={{ position: "relative", height: 80, width: 150, px: 2 }}
          key={index}
        >
          <Image
            loading="lazy"
            fill
            sizes="50vw"
            style={{ objectFit: "cover" }}
            src={`https://brainmentors-website-assets.s3.ap-south-1.amazonaws.com/${logo}`}
            alt={`Image ${index}`}
          />
        </Box>
      ))}
    </Carousel>
  );
}
