"use client";
import React from "react";
import { Grid, Skeleton } from "@mui/material";

export default function CourseCardSkeleton() {
  return (
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((value) => (
        <Grid key={value} item xs={12} sm={6} md={4} lg={3}>
          <div>
            <Skeleton variant="rectangular" height={118} />
            <br />
            <Skeleton variant="rectangular" height={22} />
            <br />
            <Skeleton variant="rectangular" height={42} width={"50%"} />
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
