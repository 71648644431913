"use client";

import React from "react";
import { z } from "zod";
import { Box, Typography } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FadeUp } from "@/src/shared/components/FadeUp";

const CourseSearchSchema = z.object({
  course: z.string().min(2, "course is required"),
});

export default function HomePageHero() {
  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<z.infer<typeof CourseSearchSchema>>({
    resolver: zodResolver(CourseSearchSchema),
    defaultValues: {
      course: "",
    },
  });
  const handleCourseChange = (event, newValue) => {
    setValue("course", newValue?.id, { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<z.infer<typeof CourseSearchSchema>> = async (
    data
  ) => {
    try {
      const response = await fetch(`/api/courses/page/${data.course}`, {
        method: "GET",
      });
      const result = await response.json();
      if (result && result?.slug) {
        window.open(`/${result?.slug}`);
      }
    } catch (err) {}
  };

  return (
    <Box
      className="index-header text-center"
      sx={{
        position: "relative",
        height: {
          xs: "30vh",
          md: "80vh",
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
        backgroundImage: {
          xs: "linear-gradient(135deg, #051937, #004d7a, #008793)",
          md: "url(/images/homehero.webp)",
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "80%", // Cover the entire width
          height: "100%",
          zIndex: 1,
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 80%)", // Gradient from left to right
          // backdropFilter: "blur(5px)", // Apply blur effect
        },
      }}
    >
      <Box
        display="flex"
        sx={{
          zIndex: 2,
          width: { xs: "90%", md: "100%" }, // Full width on small screens
          p: { md: 8 }, // Padding for mobile and larger screens
          color: "#fff",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box flex={1}>
          <FadeUp delay={0.25} duration={1}>
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontSize: { xs: "2rem", md: "3.3rem" },
              }}
            >
              Learn Skills that Hire. <br />
              <Box component="span" sx={{ color: { xs: "pink" } }}>
                100% Success Guaranteed!
              </Box>
            </Typography>
          </FadeUp>
          <FadeUp delay={0.5} duration={1}>
            <Typography
              variant="h5"
              sx={{ py: 2, fontSize: { xs: "1.2rem", md: "1.3rem" } }}
            >
              Unlock Your Future with with In-Demand Skills. Experience Hands-On
              Training, Industry-Aligned Curriculum, and 100% Placement Support.
              At Brain Mentors, your success is our commitment.
            </Typography>
          </FadeUp>
        </Box>
        <Box flex={1} sx={{ display: { xs: "none", md: "block" } }}></Box>
      </Box>
    </Box>
  );

  // return (
  //   <Box
  //     id="hero"
  //     sx={{
  //       display: "flex",
  //       // height: {
  //       //   xs: "50vh",
  //       //   md: "80vh",
  //       // },
  //       alignItems: "center",
  //       position: "relative",
  //       backgroundImage: {
  //         xs: "linear-gradient(360deg, green 10%, white 50%, orange 100%)",
  //         md: "url(https://www.edx.org/cdn-cgi/image/quality=75/images/marketing/homepage-mvp-bg-image-v2.webp)",
  //       },

  //       width: "100%",
  //     }}
  //   >
  //     <Container
  //       sx={{
  //         position: "relative",
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         pt: { xs: 14, sm: 28 },
  //         pb: { xs: 8, sm: 18 },
  //         zIndex: 2,
  //       }}
  //     >
  //       <Stack
  //         spacing={2}
  //         sx={{ width: { xs: "100%", sm: "70%", fontWeight: "bolder" } }}
  //       >
  //         <Typography
  //           variant="h1"
  //           sx={{
  //             display: "flex",
  //             flexDirection: { xs: "column", md: "row" },
  //             alignSelf: "center",
  //             textAlign: "center",
  //             fontWeight: "bold",
  //             fontSize: {
  //               xs: "3rem",
  //               sm: "clamp(4.5rem, 14vw, 5rem)",
  //             },
  //             color: "#fff",
  //           }}
  //         >
  //           We develop IT&nbsp;
  //           <Typography
  //             component="span"
  //             variant="h1"
  //             sx={{
  //               fontSize: {
  //                 xs: "2.5rem",
  //                 sm: "clamp(1rem, 10vw, 2.5rem)",
  //                 md: "3.5rem",
  //               },
  //               color: (theme) => theme.palette.primary.main,
  //             }}
  //           >
  //             Brains
  //           </Typography>
  //         </Typography>

  //         <Typography
  //           textAlign="center"
  //           color="#fff"
  //           sx={{
  //             alignSelf: "center",
  //             width: { xs: "100%", sm: "100%", md: "80%" },
  //           }}
  //           variant="h5"
  //         >
  //           Empowering minds, shaping futures - Discover the limitless
  //           possibilities with Brain-Mentors, because excellence is our
  //           standard.
  //         </Typography>
  //       </Stack>

  //       <Box
  //         sx={{ p: 1 }}
  //         mt={4}
  //         color={"Background"}
  //         display={"flex"}
  //         flexDirection={"row"}
  //         justifyContent={"center"}
  //         alignItems={"center"}
  //         gap={1}
  //         component={"form"}
  //         onSubmit={handleSubmit(onSubmit)}
  //         noValidate
  //       >
  //         <CourseDropDownListWithSearchBox onChange={handleCourseChange} />

  //         <IconButton disabled={isSubmitting} type="submit">
  //           <Search color="#fff" />
  //         </IconButton>
  //       </Box>
  //     </Container>
  //   </Box>
  // );
}
