import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/AboutUsHome.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/GoalAchieved.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/HomePageHero.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/OurCourses.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/PlacementList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/app/(client)/(home)/_components/SecondaryBanner.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/CompanyImageCarousel.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/FadeUp.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/Highlights.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/TestimonialsList.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/brainmentors2.0/src/shared/components/VideoTestimonials.tsx")