"use client";

import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Container,
  Typography,
} from "@mui/material";
import useSWR from "swr";
import SharedCarousel from "@/src/shared/components/Carousel";
import { usePathname, useRouter } from "next/navigation";

const fetcher = (url) => fetch(url).then((r) => r.json());

export default function PlacementList() {
  const pathName = usePathname();

  const { data, error, isLoading, mutate } = useSWR(
    "/api/placements",
    fetcher,
    { revalidateOnMount: true }
  );
  const router = useRouter();

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error}</p>;
  }

  const isLandingPage = pathName.split("/")[1] === "landing";
  return (
    <>
      <Box>
        <Container
          sx={{
            pt: { xs: 4, sm: 6 },
            pb: { xs: 4, sm: 12 },
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Typography variant="h2" textAlign={"center"}>
            Your Career, Our Commitment
          </Typography>
          <Typography
            mb={8}
            mt={1}
            variant="body1"
            color="text.secondary"
            textAlign={"center"}
          >
            At Brain Mentors Pvt Ltd, we understand the power of a strong career
            start for freshers and the importance of advancement for working
            professionals. Our dedicated placement support is designed to help
            you unlock your potential, achieve your career goals, and turn
            ambitions into reality. With Brain Mentors, your success is within
            reach.
          </Typography>

          <SharedCarousel>
            {data.placements.map((placement) => (
              <Card
                onClick={() => {
                  if (!isLandingPage) router.push("/placements");
                }}
                key={placement._id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  p: 1,
                  height: "100%",
                  marginX: 1,
                  ...(!isLandingPage ? { cursor: "pointer" } : {}),
                }}
              >
                <Avatar
                  alt={`${placement.name}`}
                  sx={{ width: 100, height: 100, objectFit: "contain" }}
                  src={placement.photo_url}
                  title={placement.name}
                />
                <Box display="flex" alignItems="center">
                  <CardHeader
                    title={placement.name}
                    subheader={placement.designation}
                  />
                  <Avatar
                    alt={`${placement.company_logo}`}
                    sx={{ width: 50, height: 50, objectFit: "contain" }}
                    src={placement.company_logo}
                    title={placement.company_logo}
                  />
                </Box>
              </Card>
            ))}
          </SharedCarousel>
        </Container>
      </Box>
    </>
  );
}
