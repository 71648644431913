"use client";
import React from "react";
import { Grid, Typography } from "@mui/material";
import useSWR from "swr";
import CourseCard from "./CourseCard";
import CourseCardSkeleton from "./CourseCardSkeleton";

export default function CourseBanner({
  courseCategory,
}: {
  courseCategory: string;
}) {
  const { data, isLoading } = useSWR(
    `/api/courses/category/${courseCategory}`,
    async () => {
      const response = await fetch(`/api/courses/category/${courseCategory}`, {
        method: "GET",
      });

      return await response.json();
    }
  );

  if (isLoading) {
    return <CourseCardSkeleton />;
  }

  if (data) {
    const courses = data.courses;
    if (!(courses.length > 0)) {
      return <Typography align="center">No courses found</Typography>;
    }
    return (
      <>
        <Grid container spacing={2}>
          {data.courses.map((course) => (
            <Grid key={course._id} item xs={6} sm={6} md={4} lg={3}>
              <CourseCard key={course._id} course={course} />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}
