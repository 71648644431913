"use client";

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function SharedCarousel({
  children,
  items = 4,
  showDots = false,
  infinite = true,
  autoPlay = true,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: items,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: items - 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: items - 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: items - 3,
    },
  };

  return (
    <Carousel
      showDots={showDots}
      swipeable={false}
      draggable={false}
      responsive={responsive}
      ssr={true}
      infinite={infinite}
      autoPlay={autoPlay}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      rewind={false}
      rewindWithAnimation={false}
      transitionDuration={500}
      customTransition="transform 500ms ease-in-out"
      // removeArrowOnDeviceType={["tablet", "mobile"]}
    >
      {children}
    </Carousel>
  );
}
